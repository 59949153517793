import { Clue } from "./clue";
import { Row, RowState } from "./Row";

export function About() {
  return (
    <div className="App-about">
      <br />
      <p>
        <i><strong>countrie</strong></i> is a{" "}
        <a href="https://www.powerlanguage.co.uk/wordle/">
          <i>Wordle</i>
        </a> variant where the aim is to
        <br />
        <strong><i>guess countries instead of words</i></strong>.
      </p>
      <p>
        You're still told which letters are correct, and which are in the wrong
        place.
      </p>
      <hr />
      <p>
        Here's an example:
      </p>
      <table className="Game-rows"><tbody>
        <Row
          rowState={RowState.LockedIn}
          wordLength={5}
          cluedLetters={[
            { clue: Clue.Correct, letter: "a" },
            { clue: Clue.Elsewhere, letter: "r" },
            { clue: Clue.Elsewhere, letter: "u" },
            { clue: Clue.Absent, letter: "b" },
            { clue: Clue.Elsewhere, letter: "a" },
          ]}
        />
      </tbody></table>
      <p>
        <b>B</b> isn't in the target country at all.
      </p>
      <p>
        <b className={"green-bg"}>A</b> is correct! The first letter is{" "}
        <b className={"green-bg"}>A</b>
        .<br />
        There is a second <b>A</b> somewhere in the country (but not the fifth letter).<br />
        <strong>(There may still be more <b>A</b>s in the country.)</strong>
      </p>
      <p>
        <b className={"yellow-bg"}>R</b> and <b className={"yellow-bg"}>U</b> occur <em>elsewhere</em> in the target country.
        <br />
        <strong>(Perhaps more than once. 🤔)</strong>
      </p>
      <p>
        Let's make another guess:
      </p>
      <table className="Game-rows"><tbody>
        <Row
          rowState={RowState.LockedIn}
          wordLength={7}
          cluedLetters={[
            { clue: Clue.Correct, letter: "a" },
            { clue: Clue.Correct, letter: "u" },
            { clue: Clue.Correct, letter: "s" },
            { clue: Clue.Correct, letter: "t" },
            { clue: Clue.Correct, letter: "r" },
            { clue: Clue.Elsewhere, letter: "i" },
            { clue: Clue.Elsewhere, letter: "a" },
          ]}
        />
      </tbody></table>
      <p>
        We're close!
      </p>
      <table className="Game-rows"><tbody>
        <Row
          rowState={RowState.LockedIn}
          wordLength={9}
          cluedLetters={[
            { clue: Clue.Correct, letter: "a" },
            { clue: Clue.Correct, letter: "u" },
            { clue: Clue.Correct, letter: "s" },
            { clue: Clue.Correct, letter: "t" },
            { clue: Clue.Correct, letter: "r" },
            { clue: Clue.Correct, letter: "a" },
            { clue: Clue.Correct, letter: "l" },
            { clue: Clue.Correct, letter: "i" },
            { clue: Clue.Correct, letter: "a" },
          ]}
        />
      </tbody></table>
      <p>
        Success!
      </p>
      <hr />
      <p>
        Countrie is based on <a href="https://dawdl.in/">dawdlin</a>.
        <br />
        Report issues to <a href="mailto:info@dawdl.in">info@dawdl.in</a>.
      </p>
    </div>
  );
}
